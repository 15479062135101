.footer {
	padding-top: 4rem;
	background-color: $brand-tertiary;

	.logo {
		width: 25rem;
		height: 12rem;
	}
}

.footer__content {
	@include centered-wrapper;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 2.4rem;
}

.footer__item {
	width: auto;
}

.footer__item--1 {
	text-align: center;
}

.footer__item--3 {
	.footer__link {
		@include block;
		color: $brand-primary;

		&:hover, &:active, &:focus {
			color: $brand-primary;
			text-decoration: underline;
		}
	}
}

.footer__item--4 {
	p {
		@include block;
		margin-top: .9rem;
		font-size: 1.8rem;
		font-family: $titleFont;
		color: $brand-secondary;
	}
}

.footer__link {
	@include block;
	font-size: 1.8rem;
	margin-bottom: .3rem;
	color: $brand-secondary;
	font-family: $titleFont;

	&:hover, &:active, &:focus {
		color: $brand-secondary;
		text-decoration: underline;
	}
}

.footer__bottom {
	text-align: center;
	padding: 0.4rem 2rem;

	p {
		font-size: 1rem;
		color: $brand-secondary;
	}
}

.legales {
	@include centered-wrapper;
	max-width: 72rem;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	background-color: $brand-tertiary;
	outline: 1rem solid $brand-tertiary;
	border: .1rem solid $brand-secondary;
	box-shadow: 0 1rem 2rem rgba(0,0,0,0.19), 0 .6rem .6rem rgba(0,0,0,0.23);
	display: none;

	&.show {
		display: block;
	}

	a {
		&:hover, &:active, &:focus {
			color: white;
			text-decoration: underline;
		}
	}
}

.legales__close {
	position: absolute;
	top: 1.2rem;
	right: 1.6rem;
	font-size: 2rem;
	cursor: pointer;
	color: $brand-secondary;

	&:hover, &:active, &:focus {
		color: $brand-primary;
	}
}

.legales__inner {
	margin: auto;
	padding-top: 3rem;
	padding-bottom: 2rem;
	text-align: left;

	p + h2 {
		margin-top: 2rem;
	}

	h2 {
		color: $brand-secondary;
	}

	a, p {
		color: $brand-secondary;
	}
}

.legales__title {
	display: block;
	font-size: 2rem;
	margin-bottom: 2.4rem;
	color: $brand-secondary;
	text-transform: uppercase;
}

.legales__button {
	cursor: pointer;
	font-size: 1.8rem;
	font-family: $titleFont;
	color: $brand-secondary;

	&:hover, &:active, &:focus {
		color: $brand-secondary;
		text-decoration: underline;
	}
}

