// FONT
$remInit: 10px;
$baseFontSize: 1.6rem;

$mainFont: 'Roboto, Helvetica', sans-serif;
$titleFont: 'Roboto Condensed';

$bodyBackground: white;

$brand-primary: #E6BE79;
$brand-secondary: #ffffff;
$brand-tertiary: #000000;



