::-webkit-selection {
	background: $brand-primary;
	color: white;
}
::-moz-selection {
	background: $brand-primary;
	color: white;
}
::-ms-selection {
	background: $brand-primary;
	color: white;
}
::-o-selection {
	background: $brand-primary;
	color: white;
}
::selection {
	background: $brand-primary;
	color: white;
}

body {
	color: $brand-tertiary;
	font-family: $mainFont;
	background-color: white;

	&.on-scroll {
		padding-top: 19.4rem;

		.header {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			z-index: 99;
			padding: 0.8rem;
		}

		.header__wrapper {
			display: flex;
			align-items: center;
			flex-direction: row;
			justify-content: space-between;
		}

		.header__logo {
			width: 11rem;
			height: 5.6rem;
			margin: 0;

			img {
				width: 11rem;
				height: 5.6rem;
			}
		}

		.navigation {
			max-width: 90rem;
			margin: 0;
		}

		.navigation__link {
			font-size: 1.4rem;
		}
	}
}

.h1, h1,
.h2, h2,
.h3, h3,
.h4, h4,
.h5, h5,
.h6, h6 {
	margin-top: 0;
	line-height: 1.1;
	text-align: center;
	color: $brand-tertiary;
	font-family: $titleFont;
}

.h1 {
	font-size: 3.2rem;
	margin-bottom: 1.6rem;
}

.clearfix::after {
	content: " ";
	display: block;
	height: 0;
	clear: both;
}

button,
input[type="button"],
input[type="submit"] {
	padding: 0;
	border: none;
	box-shadow: none;
	border-radius: 0;
	text-shadow: none;
	background-color: transparent;

	&:hover, &:active, &:focus {
		box-shadow: none;
		text-decoration: none;
	}
}


ul, ol {
	margin: 0;
	list-style: none;

	li {
		list-style: inside;
	}
}

ul {
	padding-left: 0;
	margin-bottom: 1rem;
}

p + ul {
	margin-top: 1rem;
}

ul, li, p, a {
	line-height: 1.2;
	font-size: 1.8rem;
	color: $brand-tertiary;
}

p {
	margin: 0;
}

a {
	color: $brand-primary;
	transition: background-color .3s ease;

	&:hover, &:active, &:focus {
		color: $brand-tertiary;
		text-decoration: underline;
	}

	&.btn {
		display: inline-block;
		font-size: 2rem;
		text-decoration: none;
		font-family: $mainFont;
		color: $brand-secondary;
		background-color: $brand-primary;
		text-transform: uppercase;
		padding: 1rem 2.5rem 1.2rem;

		span {
			margin-left: 1rem;
		}

		&:hover, &:active, &:focus {
			text-decoration: none;
			color: $brand-secondary;
			background-color: $brand-primary;
		}
	}

	&.link--cadre {
		display: block;
		width: 20rem;
		margin: 2.4rem auto 0;
		padding: .7rem;
		font-size: 1.4rem;
		text-align: center;
		color: $brand-secondary;
		font-family: $titleFont;
		background-size: contain;
		background-position: 0 0;
		text-transform: uppercase;
		background-repeat: no-repeat;
		background-image: url('../img/link.svg');

		&:hover, &:active, &:focus {
			color: $brand-primary;
		}
	}
}

p + .btn {
	margin-top: 4.5rem;
}

p + p {
	margin-top: 2rem;
}

// formulaires
@mixin formInput()
{
	@include block;
	width: 100%;
	height: 3.6rem;
	border-radius: 0;
	font-size: 1.6rem;
	box-shadow: inherit;
	padding: .6rem 1rem;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	color: $brand-tertiary;
	font-family: $titleFont;
	background-color: white;
	border: .1rem solid $brand-primary;
}

@mixin formSubmit(){
	@include block;
	width: auto;
	margin: auto;
	cursor: pointer;
	font-weight: 700;
	font-size: 1.6rem;
	text-decoration: none;
	-moz-appearance: none;
	font-family: $mainFont;
	color: $brand-primary;
	font-family: $titleFont;
	text-transform: uppercase;
	padding: 0.4rem 3.8rem 0.6rem;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	background-color: $brand-tertiary;
	border-bottom: .3rem solid $brand-primary;

	&:hover, &:active, &:focus {
		color: $brand-secondary;
		background-color: $brand-tertiary;
	}
}

.ninja {
	position: relative;
	display: block;
	height: 0rem;
	width: 0rem;
	visibility: hidden;
	overflow: hidden;
}

input[type="url"],
input[type="text"],
input[type="tel"],
input[type="date"],
input[type="week"],
input[type="time"],
input[type="phone"],
input[type="range"],
input[type="month"],
input[type="color"],
input[type="email"],
input[type="search"],
input[type="number"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
textarea,
select {
	@include formInput;
}

textarea {
	resize: vertical;
	min-height: 12rem;
	padding: .6rem 1rem;
	margin-bottom: 2rem;
}

.form-title {
	font-weight: bold;
	margin-bottom: 1.5rem;
}

form {
	button,
	input[type="button"],
	input[type="submit"] {
		@include formSubmit;
		margin-top: 4rem;
	}

	p {
		margin-bottom: 2rem;
	}

	.form-group {
		@include block;
		width: 100%;
		margin-bottom: 1.5rem;
	}

	label {
		@include block;
		line-height: 1.2;
		font-size: 1.2rem;
		margin-bottom: 0.6rem;
	}

	.checkbox {
		display: none;
	}

	.checkbox + label {
		@include inline-block;
		cursor: pointer;
		padding: 0.7rem;
		box-shadow: none;
		border: 0.2rem solid $brand-tertiary;
	}

	.checkbox:checked + label {
		color: $brand-tertiary;
		box-shadow: none;
		background-color: $brand-secondary;
		border: 0.2rem solid $brand-tertiary;

		&:after {
			content: '\2714';
			position: absolute;
			top: 0rem;
			left: 0.2rem;
			color: $brand-tertiary;
			font-size: 1.3rem;
		}
	}

	.check__label {
		width: calc(100% - 3rem);
		float: right;
		margin-bottom: 3rem;

		a {
			line-height: 1.2;
			font-size: 1.2rem;
			color: $brand-tertiary;

			&:hover, &:active, &:focus {
				color: $brand-tertiary;
				text-decoration: underline;
			}
		}

	}
}

.alert__message {
	display: none;
	width: 100%;
	color: white;
	padding: 1rem;
	border-radius: 0;
	font-size: 1.6rem;
	margin-top: 2rem;
	text-align: center;
	margin-bottom: 1.5rem;
}

.alert__success {
	display: block;
	background-color: $brand-tertiary;
}

.alert__error {
	display: block;
	background-color: #ce2c2c;
}

.bkoformidable {
	input[type="submit"] {
		margin-top: 1.8rem;
	}
}

#page {
	z-index: 1;
	transition: transform 0.3s;
}

.bloc-title,
.entry-title,
.page-title {
	margin: 0;
	line-height: 1;
	font-size: 3.9rem;
	font-weight: bold;
	margin-top: 3.5rem;
	margin-bottom: 4rem;
	color: $brand-primary;
}

.mobile {
	@include fixed-block;
	right: 0;
	top: 0;
	z-index: 9999;
	width: 100vw;
	height: 100%;
	min-height: 100vh;
	overflow: auto;
	padding-top: 6rem;
	font-weight: bold;
	transition: transform 0.3s;
	transform: translateY( -100vh );
	background-color: $brand-tertiary;

	.mobile__fermeture {
		@include block;
		width: 23rem;
		margin: auto;
		cursor: pointer;
		font-size: 2.5rem;
		font-weight: bold;
		text-align: center;
		padding-bottom: 1rem;
		color: $brand-secondary;
		text-transform: uppercase;
		border-bottom: .2rem dotted $brand-secondary;

		span {
			@include inline-block;
			vertical-align: middle;
		}

		.cross {
			font-size: 4rem;
			margin-left: 1rem;
			font-weight: lighter;
			transform: rotateZ(45deg);
		}

		&:hover, &:active, &:focus {
			color: $brand-primary;
		}
	}

	ul {
		padding: 0;
		margin: 3rem 0 5rem;

		a {
			display: block;
			color: white;
			font-size: 1.2rem;
			text-align: center;
			padding: 0.7rem 2rem;
			color: $brand-primary;

			&:hover, &:active, &:focus {
				color: $brand-secondary;
			}
		}

		li {
			position: relative;
			list-style: none;
			text-transform: uppercase;

			a {
				font-size: 2rem;
			}

			&:last-child {
				border-bottom: none;

				a {
					border-bottom: none;
				}
			}

			&.herader-langue {
				text-align: center;

				a {
					display: inline-block;
				}
			}
		}

		svg {
			width: 3rem;
			height: 3rem;

			path {
				fill: white;
			}
		}
	}

	img {
		display: block;
		margin: auto;
		margin-bottom: 6rem;
	}
}

.openMenu {
	.mobile{
		transform: translateY( 0 );
	}
}
