.header {
	@include block;
	height: auto;
	width: 100%;
	text-align: center;
	padding: 2rem 2rem 1rem;
	transition: all .2s ease;
	background-color: $brand-tertiary;
}

.header__wrapper {
	@include centered-wrapper;
	max-width: 146rem;
}

.header__logo {
	@include block;
	width: 25rem;
	height: 12.3rem;
	margin: auto;

	img {
		width: 25rem;
		height: 12.3rem;
	}
}

.header__baseline {
	@include block;
	font-size: 2rem;
	margin-top: 2.4rem;
	font-weight: normal;
	color: $brand-tertiary;
	text-transform: inherit;
}

.hamburger {
	display: none;
	position: absolute;
	top: 1rem;
	right: .8rem;
	width: 2rem;
	height: 1.2rem;
	margin: 0;
	cursor: pointer;
	margin-top: 1.2rem;
	vertical-align: top;
	transform: rotate(0deg);
	transition: .5s ease-in-out;

	div {
		position: absolute;
		display: block;
		height: .2rem;
		width: 100%;
		background: $brand-primary;
		border-radius: .2rem;
		opacity: 1;
		left: 0;
		transform: rotate(0deg);
		transition: .25s ease-in-out;

		&:nth-child(1) {
			top: 0;
			transform-origin: left center;
		}

		&:nth-child(2) {
			top: .5rem;
			transform-origin: left center;
		}

		&:nth-child(3) {
			top:  1rem;
			transform-origin: left center;
		}
	}

	&.openBurger div {
		&:nth-child(1) {
			transform: rotate(45deg);
			top: -.2rem;
			left: .3rem;
		}

		&:nth-child(2) {
			width: 0%;
			opacity: 0;
		}

		&:nth-child(3) {
			transform: rotate(-45deg);
			top: 1.2rem;
			left: .3rem;
		}
	}
}

.navigation {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	padding: 0 1.6rem;
	margin: 1.6rem auto 0;
}

.navigation__link {
	position: relative;
	font-size: 2rem;
	color: $brand-primary;
	font-family: $titleFont;
	text-transform: uppercase;

	&:before {
		content: '';
		position: absolute;
		left: 50%;
		bottom: -.5rem;
		width: 0;
		height: .2rem;
		transform: translateX(-50%);
		transition: width .2s ease;
		background-color: $brand-primary;
	}

	&:hover, &:active, &:focus {
		color: $brand-primary;
		text-decoration: none;

		&:before {
			width: 4rem;
		}
	}
}
