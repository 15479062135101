.section {
	@include block;
	padding-top: 8rem;
	padding-bottom: 8rem;
}

.section__wrapper {
	@include centered-wrapper;
}

.section__text {
	@include block;
	width: 100%;
	text-align: justify;
}

.section__image {
	@include block;
	width: 100%;
	text-align: justify;
}

.title {
	font-size: 3.2rem;
	text-align: center;
	font-family: $titleFont;
	text-transform: uppercase;

	span {
		@include block;
		font-weight: normal;
	}
}

.sub__title {
	@include block;
	font-size: 3.2rem;
	margin-top: .8rem;
	text-align: center;
	font-family: $titleFont;
	text-transform: uppercase;
}

.section--1 {
	.section__wrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.section__image,
	.section__text {
		width: 50%;
		padding-left: 4.8rem;
		padding-right: 4.8rem;
	}

	.sub__title {
		margin-top: 1.6rem;
		margin-bottom: 2.4rem;
		text-transform: initial;
	}
}

.section--2 {
	padding-top: 6.4rem;
	padding-bottom: 8rem;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-image: url('../img/bg.jpg');

	.section__title {
		@include centered-wrapper;
	}

	.title {
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: center;
	}

	.sub__title {
		font-size: 2.8rem;
		margin-top: 1.6rem;
		margin-bottom: 4.8rem;
		text-transform: initial;
	}

	.logo {
		width: 15rem;
		height: 6rem;
		margin: 0 1.6rem;
	}

	.label {
		width: 10rem;
		height: 10rem;
		margin-left: 4rem;
	}

	.section__wrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.section__image,
	.section__text {
		width: 50%;
		padding-left: 4.8rem;
		padding-right: 4.8rem;
	}

	.link--bg {
		@include block;
		width: 100%;
		height: auto;
		margin-top: 4.7rem;
		padding: 10rem 2rem;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-image: url('../img/img-2-bis.jpg');
	}

	.link--cadre {
		width: 42rem;
		margin: 0 auto;
		padding: 1.6rem;
		font-size: 3rem;
		text-transform: initial;
	}
}

.section--3 {
	padding-top: 10rem;

	.title {
		text-transform: initial;
	}

	.sub__title {
		margin-bottom: 6.4rem;
	}

	.section__wrapper {
		padding-left: 6.8rem;
		padding-right: 6.8rem;
	}

	p, a {
		font-size: 1.8rem;
		text-align: justify;
	}
}

.section--4 {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-image: url('../img/bg.jpg');

	.section__wrapper {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}

	.section__title {
		margin-bottom: 4.8rem;
	}

	.title {
		text-transform: initial;
	}

	.section__image,
	.section__text {
		width: 50%;
		padding-left: 4.8rem;
		padding-right: 4.8rem;
	}

	.section__text {
		p {
			font-size: 2rem;

			span {
				@include block;
				font-size: 2rem;
				margin-top: 2rem;
				font-weight: bold;
			}
		}

		img {
			display: block;
			max-width: 38rem;
			margin: 2rem auto 0;
		}
	}

	.link--cadre {
		margin-top: 4rem;
	}
}

.plante__tabs {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	max-width: 66rem;
	width: 100%;
	margin: auto;
	margin-top: 4rem;
	margin-bottom: 3.2rem;
}

.plante__tab {
	cursor: pointer;
	font-size: 1.6rem;
	border-radius: .3rem;
	color: $brand-primary;
	padding: .4rem 1.2rem;
	font-family: $titleFont;
	border: .1rem solid $brand-primary;

	&.highlightTab, &:hover, &:active, &:focus {
		text-decoration: none;
		color: $brand-secondary;
		background-color: $brand-primary;
		border: .1rem solid $brand-primary;
	}
}

.plante--1,
.plante--2,
.plante--3,
.plante--4,
.plante--5,
.plante--6,
.plante--7 {
	display: none;
	overflow: hidden;

	&.showTab {
		display: block;
	}
}

.plante__wrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
}

.plante__item {
	@include block;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 50%;
	padding: 4rem;
}

.plante__img {
	flex: 0 0 21rem;
	width: 100%;
	padding-right: 2.4rem;

	img {
		width: 100%;
		height: auto;
	}
}

.plante__title {
	text-align: left;
	font-size: 3.2rem;
	margin-bottom: 2.4rem;
}

.plante__text {
	width: 100%;
	font-size: 1.8rem;
}

.section--5 {
	.title {
		text-transform: initial;
	}
}

.section--6 {
	padding-top: 0;
	padding-bottom: 0;
}

.section--7 {
	background-color: $brand-primary;

	.sub__title {
		margin-top: 0;
		text-transform: initial;
	}

	.section__wrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-top: 4.8rem;
		padding-left: 6.8rem;
		padding-right: 6.8rem;
	}

	.logo {
		width: 19rem;
		height: 9rem;
		margin-bottom: 3.8rem;
	}

	.contact__infos {
		width: 33%;
		text-align: center;

		p, a {
			font-size: 2.4rem;
			font-family: $titleFont;
		}

		p {
			margin-bottom: 2.4rem;
			text-transform: uppercase;
		}

		a {
			color: $brand-tertiary;
		}
	}

	.contact__form {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;
		width: 66%;
	}

	.contact__form--right {
		width: 50%;
		padding-left: 3.2rem;
	}

	.contact__form--left {
		width: 50%;
		padding-right: 3.2rem;
	}

	.contact__form--bottom {
		width: 100%;
	}

	textarea {
		height: 23.9rem;
		resize: none;
	}
}

.section--8 {
	.section__wrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.section__image,
	.section__text {
		width: 50%;
		padding-left: 4.8rem;
		padding-right: 4.8rem;
	}

	.title {
		margin-bottom: 2.4rem;
	}
}
