/* roboto-condensed-v19-latin-regular - 400 */
@font-face {
	font-family: 'Roboto Condensed';
	font-style: normal;
	font-weight: 400;
	src: local('Roboto Condensed Regular'), local('RobotoCondensed-Regular'),
		url('../fonts/roboto-condensed-v19-latin-regular.woff2') format('woff2'),
		url('../fonts/roboto-condensed-v19-latin-regular.woff') format('woff'),
		url('../fonts/roboto-condensed-v19-latin-regular.ttf') format('truetype');
}

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto Regular'), local('Roboto-Regular'),
	   url('../fonts/roboto-v27-latin-regular.woff2') format('woff2'),
	   url('../fonts/roboto-v27-latin-regular.woff') format('woff'),
	   url('../fonts/roboto-v27-latin-regular.ttf') format('truetype');
}
