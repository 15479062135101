@media(max-width: 1499px)
{
	.header__logo,
	.header__logo img,
	.footer .logo {
		width: 19rem;
		height: 9.5rem;
	}

	.navigation__link {
		font-size: 1.7rem;
	}
}

@media(max-width: 1279px)
{
	.header__logo,
	.header__logo img,
	.footer .logo {
		width: 14rem;
		height: 7rem;
	}

	.header__wrapper {
		padding-left: 0;
		padding-right: 0;
	}
	.navigation__link {
		font-size: 1.5rem;
	}

	.title {
		font-size: 2.6rem;
	}

	.sub__title,
	.section--2 .sub__title,
	.h1 {
		font-size: 2.2rem;
	}

	.section--7 .contact__infos p,
	.section--7 .contact__infos a {
		font-size: 2rem;
	}

	ul, li, p, a,
	.section--3 p,
	.section--3 a,
	.footer__link,
	.legales__button,
	.footer__item--4 p {
		font-size: 1.6rem;
	}

	.section--2 .logo {
		width: 10rem;
		height: 4.6rem;
	}

	.section--7 .logo {
		width: 14rem;
		height: 6.2rem;
	}

	.section--2 .label {
		width: 7rem;
		height: 7rem;
	}

	body.on-scroll .navigation {
		max-width: 83rem;
	}
	body.on-scroll .navigation__link {
		font-size: 1.3rem;
	}

	.section--1 .section__image,
	.section--1 .section__text,
	.section--2 .section__image,
	.section--2 .section__text,
	.section--4 .section__image,
	.section--4 .section__text,
	.section--8 .section__image,
	.section--8 .section__text {
		padding-left: 2.4rem;
		padding-right: 2.4rem;
	}

	.section--3 .section__wrapper,
	.section--7 .section__wrapper {
		padding-left: 4.4rem;
		padding-right: 4.4rem;
	}

	.section--2 .link--cadre {
		width: 29rem;
		padding: 1.1rem;
		font-size: 2rem;
	}

	.section--2 .link--bg {
		margin-top: 4rem;
		padding: 7.6rem 2rem;
	}

	.section--3 .sub__title {
		margin-bottom: 3.4rem;
	}

	.section--4 .section__text p,
	.section--4 .section__text p span {
		font-size: 2rem;
	}

	.plante__title {
		font-size: 2.2rem;
		margin-bottom: 1.6rem;
	}

	.plante__item {
		padding: 2rem;
	}
}

@media(max-width: 1024px)
{
	.header {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 99;
		padding: 0.8rem;
	}

	.header__wrapper {
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: space-between;
	}

	.header__logo,
	.header__logo img,
	.footer .logo {
		width: 11rem;
		height: 5.6rem;
		margin: 0;

		img {
			width: 11rem;
			height: 5.6rem;
		}
	}

	.navigation {
		display: none;
	}

	.hamburger {
		display: block;
	}

	.main__wrapper {
		padding-top: 7.2rem;
	}
}

@media(max-width: 990px)
{
	.section--2 .link--bg {
		padding: 0;
		background: none;
	}

	.plante__tabs {
		justify-content: center;
		flex-wrap: wrap;
	}

	.plante__tab {
		margin: 0 .4rem 0.8rem;
	}

	.plante__item {
		flex-direction: column;
		justify-content: flex-start;
	}

	.plante__img {
		flex: 0 0 auto;
		padding-right: 0;

		img {
			display: block;
			width: 15rem;
			margin: auto;
			margin-bottom: 2rem;
		}
	}

	.plante__title,
	.plante__text {
		text-align: center;
	}

	.section--7 .contact__form--left {
		padding-right: 1.6rem;
	}

	.section--7 .contact__form--right {
		padding-left: 1.6rem;
	}

	.footer__link,
	.legales__button,
	.footer__item--4 p {
		font-size: 1.4rem;
	}
}

@media(max-width: 767px)
{
	.section--1 .section__wrapper,
	.section--2 .section__wrapper,
	.section--4 .section__wrapper,
	.section--7 .section__wrapper,
	.section--8 .section__wrapper,
	.section--7 .contact__form,
	.footer__content {
		flex-direction: column;
		justify-content: flex-start;
	}

	.section--1 .section__image,
	.section--1 .section__text,
	.section--2 .section__image,
	.section--2 .section__text,
	.section--4 .section__image,
	.section--4 .section__text,
	.section--8 .section__image,
	.section--8 .section__text,
	.section--7 .contact__infos,
	.section--7 .contact__form,
	.section--7 .contact__form--left,
	.section--7 .contact__form--right {
		padding-left: 0;
		padding-right: 0;
		width: 100%;
	}

	.section--1 .section__image,
	.section--2 .section__image,
	.section--4 .section__image,
	.section--8 .section__image {
		text-align: center;
		margin-bottom: 1.6rem;
	}

	.section--3 .section__wrapper,
	.section--7 .section__wrapper {
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.section--2 .title {
		flex-direction: column;
	}

	.section--2 .label {
		margin-left: 0;
		margin-top: 1.6rem;
	}

	.plante__item {
		width: 100%;
		padding: 0;

		&.plante__item {
			padding-bottom: 4rem;
		}
	}

	.section--6 iframe {
		height: 36rem;
	}

	.section--7 .logo {
		margin-bottom: 2rem;
	}

	.section--7 .contact__infos {
		margin-bottom: 4rem;
	}

	.footer__item {
		text-align: center;
	}

	.footer__item--1,
	.footer__item--2,
	.footer__item--3 {
		margin-bottom: 1.6rem;
	}
}
